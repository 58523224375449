var md = new MobileDetect(window.navigator.userAgent);
var ua = detect.parse(navigator.userAgent);
// console.log("mobile");
// console.log(md.mobile()); // 'Sony'
// console.log(md.phone()); // 'Sony'
// console.log(md.tablet()); // null
// console.log(md.userAgent()); // 'Safari'
// console.log(md.os()); // 'AndroidOS'
// console.log(md.is("iPhone")); // false
// console.log(md.is("bot")); // false
// console.log(md.version("Webkit")); // 534.3
// console.log(md.versionStr("Build")); // '4.1.A.0.562'
// console.log(md.match("playstation|xbox")); // false

// console.log("detect");
// console.log(ua.browser);
// console.log(ua.device);
// console.log(ua.os);
//test 2
var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight || e.clientHeight || g.clientHeight;

var ismobile = false;
var openMega = null;
var currentPage = 1;
var isplaying = false;
var elemets = null;
if (md.mobile())
    ismobile = true;
// scrollmagic init
let ctrl = new ScrollMagic.Controller();

// Define the Function targetBlank()
function targetBlank() {
    // remove subdomain of current site's url and setup regex
    var internal = location.host.replace("www.", "");
    internal = new RegExp(internal, "i");

    var a = document.getElementsByTagName('a'); // then, grab every link on the page
    for (var i = 0; i < a.length; i++) {
        var href = a[i].host; // set the host of each link
        if ((!internal.test(href) && !/^javascript/.test(a[i].getAttribute("href"))) || /\.pdf$/.test(a[i].getAttribute("href"))) { // make sure the href doesn't contain current site's host
            a[i].setAttribute('target', '_blank'); // if it doesn't, set attributes
        }
    }
}

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function checkItems(gl) {
    if (gl.slides_count <= gl.settings.perView) {
        gl.update({startAt: 0}).disable();
        gl.elem.querySelectorAll('.glide__arrows').forEach((el) => {
            el.classList.add('d-none')
        });
        gl.elem.querySelectorAll('.glide__bullets').forEach((el) => {
            el.classList.add('d-none')
        });
    } else {
        gl.enable();
        gl.elem.querySelectorAll('.glide__arrows').forEach((el) => {
            el.classList.remove('d-none')
        });
        gl.elem.querySelectorAll('.glide__bullets').forEach((el) => {
            el.classList.remove('d-none')
        });
    }
}

function filterProjects(filter) {
    console.log(filter)
    if (!elemets) {
        elemets = [];
        [].forEach.call(document.querySelectorAll('.projekte .gird-item'), function (e, index) {
            elemets.push(e);
        });
    }
    let parent = document.querySelector(".projekte");

    for (let j = 0; j < elemets.length; j++) {
        e = elemets[j];
        e.querySelector(".img-reveal").classList.remove('reveal');
        e.querySelector(".img-reveal").classList.remove('reveal-ready');
        if (e.parentElement)
            parent.removeChild(e);
        let f = true;
        let farr = filter.split(' ');
        for (let i = 0; i < farr.length; i++) {
            if (!e.classList.contains(farr[i]) && farr[i] !== '*')
                f = false;
        }
        if (f) {
            console.log(filter);
            parent.appendChild(e);
            //e.querySelector(".img-reveal").classList.add('reveal');
        }
    }

    [].forEach.call(document.querySelectorAll('.projekte .img-reveal'), function (e, index) {
        // scrollmagic scene
        let offset = -300;
        if (index % 2 === 0 && index > 2)
            offset += 100;
        //console.log(index);
        let scene = new ScrollMagic.Scene({triggerElement: e, triggerHook: .6, offset: offset, reverse: false})
            .setClassToggle(e, "reveal") // add class toggle
            .on("start", function (ev) {
                //console.log(e);
                e.classList.add('reveal-ready')
            })
            .addTo(ctrl);

    });
}

(function () {
    "use strict";

    // Run the function targetBlank()
    targetBlank();
    if (document.querySelectorAll(".scroll-top").length > 0) {
        document.querySelectorAll('.scroll-top')[0].addEventListener("click", function (event) {
            window.scrollTo({
                'behavior': 'smooth',
                'left': 0,
                'top': 0
            });
        });
    }


    if (document.querySelectorAll("button.navbar-toggler").length > 0) {

        anime({
            targets: '.navbar-nav li',
            translateY: 0,
            opacity: 1,
            delay: anime.stagger(20)
        });
        // Mobile toggler
        document.querySelectorAll("button.navbar-toggler")[0].addEventListener("click", function (event) {
            var target = document.querySelectorAll('.navbar-collapse')[0];
            this.className = (this.className + " open").replace(/ open open/, "");
            target.className = (target.className + " show").replace(/ show show/, "");
            var target2 = document.querySelectorAll('.navbar')[0];
            target2.className = (target2.className + " open").replace(/ open open/, "");

            if (target.classList.contains("show")) {
                setTimeout(function () {
                    anime({
                        targets: '.navbar-collapse li',
                        translateY: 0,
                        opacity: 1,
                        easing: 'easeOutQuad',
                        duration: 300,
                        delay: anime.stagger(60)
                    });
                }, 450);
            } else {
                anime({
                    targets: '.navbar-collapse li',
                    translateY: 200,
                    opacity: 0,
                    duration: 100,
                    delay: anime.stagger(0)
                });
            }
        });
    }

    // Wait for Images
    imagesLoaded(document.querySelector("main"), function (instance) {

        // fadein main
        var tlinit = anime.timeline({
            easing: 'linear'
        });

        tlinit.add({
            targets: '.lds-dual-ring',
            opacity: 0,
            easing: 'easeInOutSine',
            duration: 50
        }, '+=0');
        /*tlinit.add({
            targets: '.navbar',
            opacity: 1,
            easing: 'easeInOutSine',
            duration: 250
        }, '+=0');*/
        tlinit.add({
            targets: 'main',
            opacity: 1,
            easing: 'easeInOutSine',
            duration: 150
        }, '+=200');

        anime({
            targets: '.scroll-circle',
            loop: true,
            translateY: 5,
            easing: 'easeInOutSine',
            duration: 650
        });

        var i = 0;
        document.querySelectorAll('.letters-ani').forEach((elem) => {
            elem.classList.add("letter-ani-" + i)

            elem.innerHTML = elem.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
            tlinit.add({
                targets: '.letter-ani-' + i + ' .letter',
                rotateY: [-90, 0],
                duration: 300,
                delay: (el, i) => 45 * i
            }, '1200')

            i++;
        });


        tlinit.play();

        if (document.querySelectorAll(".farbpalette").length > 0) {
            const colorThief = new ColorThief();
            const img = document.querySelector('.farbpalette-img');
            let target = document.querySelector(".farbpalette-div");
            console.log(colorThief.getColor(img));

            document.querySelectorAll(".pipette-icon")[0].addEventListener("click", function (event) {
                target.innerHTML = '';
                const palette = colorThief.getPalette(img, 9, 1);
                for (let i = 0; i < palette.length; i++) {
                    let c = rgbToHex(palette[i][0], palette[i][1], palette[i][2]);

                    let span = '<span class="pcolor" style="background-color:' + c + '"></span>';

                    setTimeout(function () {
                        target.insertAdjacentHTML('beforeend', span);
                    }, i * 50);

                    //Do something
                }
            });


        }

// each image
        [].forEach.call(document.querySelectorAll('.img-reveal'), function (e, index) {
            // scrollmagic scene
            let offset = -300;
            if (index % 2 === 0 && index > 2)
                offset += 100;

            if (!e.classList.contains('hide')) {
                console.log(index);
                let scene = new ScrollMagic.Scene({triggerElement: e, triggerHook: .6, offset: offset, reverse: false})
                    .setClassToggle(e, "reveal") // add class toggle
                    .on("start", function (ev) {
                        //console.log(e);
                        e.classList.add('reveal-ready')
                    })
                    .addTo(ctrl);
            }

        });

        if (document.querySelectorAll('.btn-mehr')[0]) {
            document.querySelectorAll('.btn-mehr')[0].addEventListener("click", function (event) {
                this.parentNode.removeChild(this);
                [].forEach.call(document.querySelectorAll('.grid.aktuelles .hide'), function (e2, index) {
                    e2.classList.remove('hide');
                    [].forEach.call(e2.querySelectorAll('.img-reveal'), function (e, index) {
                        // scrollmagic scene
                        let offset = -300;
                        if (index % 2 === 0 && index > 2)
                            offset += 100;

                        console.log(index);
                        let scene = new ScrollMagic.Scene({triggerElement: e, triggerHook: .6, offset: offset, reverse: false})
                            .setClassToggle(e, "reveal") // add class toggle
                            .on("start", function (ev) {
                                //console.log(e);
                                e.classList.add('reveal-ready')
                            })
                            .addTo(ctrl);


                    });
                });
            });
        }



    });


    document.querySelectorAll('.scene').forEach((e, index) => {
        let offset = y / 2;
        if (e.classList.contains('divider')) {
            offset = -y / 2;
        }
        if (e.classList.contains('claim')) {
            offset = y / 4;
        }
        var plinit = anime.timeline({
            easing: 'linear',
            autoplay: false,
            loop: false,
        });
        plinit.add({
            targets: e,
            translateY: [0, 20 * e.getAttribute('data-modifier')],
            duration: 10000
        }, '+=0');
        let scene = new ScrollMagic.Scene({triggerElement: e, offset: offset, duration: "150%"});
        scene.on('progress', function (event) {
            plinit.seek(event.progress * 1000);
        });
        scene.addTo(ctrl);
    });

    document.querySelectorAll('.projekte-filter li span.filter-lev1').forEach((elem) => {
        elem.addEventListener("click", function (event) {
            document.querySelectorAll('.projekte-filter li span.filter-lev1').forEach((ele) => {
                ele.parentElement.classList.remove('active');
            })
            elem.parentElement.classList.add('active');
            filterProjects(elem.parentElement.getAttribute('data-filter'));
        });
    });

    document.querySelectorAll('.projekte-filter li span.filter-lev2').forEach((elem) => {
        elem.addEventListener("click", function (event) {
            document.querySelectorAll('.projekte-filter li span.filter-lev2').forEach((ele) => {
                ele.parentElement.classList.remove('active');
            })
            elem.parentElement.classList.add('active');
            filterProjects(elem.parentElement.getAttribute('data-filter'));
        });
    });


    // Init Aktuell Sliders
    document.querySelectorAll('.glide-aehnliche').forEach((elem) => {
        // console.log('mount2');
        var slides_count = document.querySelectorAll('.glide-aehnliche .glide__slide').length;
        var type = 'carousel';
        if (slides_count < 3)
            type = 'slider';
        var gl = new Glide(elem, {
            perView: 3,
            gap: 2,
            type: type,
            bound: true,
            breakpoints: {
                993: {
                    perView: 2
                },
                800: {
                    perView: 2
                },
                600: {
                    perView: 1
                },
                400: {
                    perView: 1
                }
            }
        });
        gl.slides_count = slides_count;
        gl.elem = elem;
        gl.on('run', () => {
            [].forEach.call(document.querySelectorAll('.glide__slides .img-reveal'), function (e, index) {
                e.classList.add('reveal');
            });
        })
        gl.on('mount.after', function () {
            checkItems(gl)
        });
        gl.on('resize', function () {
            checkItems(gl)
        });

        gl.mount()
    });

    if (document.querySelectorAll('.myVideo')[0]) {

        var vid = document.getElementById("myVideo");
        console.log('set events')
        if (vid) {
            console.log('set events')
            vid.onplay = function () {
                console.log('playing')
                isplaying = true;
            };
            vid.onended = function () {
                console.log('ended')
                isplaying = false;
                var tlstart = anime.timeline({
                    easing: 'linear'
                });
                tlstart.add({
                    targets: '.play-button-outer',
                    opacity: 1,
                    easing: 'easeInOutSine',
                    duration: 550
                }, '+=0');
                tlstart.add({
                    targets: '.poster-image',
                    opacity: 1,
                    easing: 'easeInOutSine',
                    duration: 50
                }, '+=0');

            };
            vid.onpause = function () {
                console.log('pause')
                isplaying = false;
            };

        }

        document.querySelectorAll('.play-button-outer')[0].addEventListener("click", function (event) {

            if (isplaying) {
                vid.pause();
                // fadein main
                var tlstart = anime.timeline({
                    easing: 'linear'
                });
                tlstart.add({
                    targets: '.play-button-outer',
                    opacity: 1,
                    easing: 'easeInOutSine',
                    duration: 550
                }, '+=0');
                tlstart.add({
                    targets: '.poster-image',
                    opacity: 1,
                    easing: 'easeInOutSine',
                    duration: 50
                }, '+=0');
            } else {
                vid.play();

                console.log('vid play')
                // fadein main
                var tlstart = anime.timeline({
                    easing: 'linear'
                });
                tlstart.add({
                    targets: '.play-button-outer',
                    opacity: 0,
                    easing: 'easeInOutSine',
                    duration: 550
                }, '+=0');
                tlstart.add({
                    targets: '.poster-image',
                    opacity: 0,
                    easing: 'easeInOutSine',
                    duration: 50
                }, '+=0');
            }


        });

        document.querySelectorAll('.poster-image')[0].addEventListener("click", function (event) {

            vid.pause();
            // fadein main
            var tlstart = anime.timeline({
                easing: 'linear'
            });
            tlstart.add({
                targets: '.play-button-outer',
                opacity: 1,
                easing: 'easeInOutSine',
                duration: 550
            }, '+=0');
            tlstart.add({
                targets: '.poster-image',
                opacity: 1,
                easing: 'easeInOutSine',
                duration: 50
            }, '+=0');


        });


    }

})();

// navbar shrink
window.addEventListener("scroll", function () {
    var nav = document.getElementById("mainNav");
    var scrollI = document.getElementById("scrollIcon");
    if (window.scrollY > 140) {
        nav.classList.add("shrink");
        if (scrollI)
            scrollI.classList.add("hide");
    } else {
        nav.classList.remove("shrink");
    }

    if (document.querySelectorAll(".scroll-top").length > 0) {
        if (window.scrollY > 240) {
            document.querySelectorAll('.scroll-top')[0].classList.add('show');
        } else {
            document.querySelectorAll('.scroll-top')[0].classList.remove('show');
        }
    }
});

// browser-warning
if (ua.browser.family === "IE") {
    const warning = document.querySelectorAll('.browser-warning');
    warning[0].className = 'browser-warning show';
}


function initMap() {
    if (document.getElementById('map')) {
        console.log('init map')
        // Create an array of styles.
        var styles = [
            {
                stylers: [
                    {hue: "#AF7966"},
                    {saturation: -50}
                ]
            }, {
                featureType: "road",
                elementType: "geometry",
                stylers: [
                    {lightness: 100},
                    {visibility: "simplified"}
                ]
            }, {
                featureType: "poi.business",
                stylers: [{ visibility: "off" }],
            },
        ];

        // Create a new StyledMapType object, passing it the array of styles,
        // as well as the name to be displayed on the map type control.
        var styledMap = new google.maps.StyledMapType(styles,
            {name: "Styled Map"});
        // Create a map object, and include the MapTypeId to add
        // to the map type control.
        var mapOptions = {
            zoom: 17,
            center: new google.maps.LatLng(47.367702659208796, 8.560567946827979),
            mapTypeControlOptions: {
                mapTypeIds: ['map_style']
            },
            disableDefaultUI: true
        };
        var map = new google.maps.Map(document.getElementById('map'),
            mapOptions);

        //Associate the styled map with the MapTypeId and set it to display.
        map.mapTypes.set('map_style', styledMap);
        map.setMapTypeId('map_style');


        var image = '/resources/img/marker.png';
        var beachMarker = new google.maps.Marker({
            position: {lat: 47.367702659208796,  lng: 8.560567946827979},
            map: map,
            icon: image
        });

    }
}
